
import { Component, Prop, Vue } from 'vue-property-decorator';
import VTable from '../shared/table/VTable.vue';
import { ColumnHeader } from '@/interfaces/components/ColumnHeader';
import { Customer } from '@/interfaces/models/Customer';
import headers from './headers';

@Component({
  components: { VTable },
})
export default class CustomerTable extends Vue {
  @Prop({ type: Array, required: true }) public items!: Customer[];
  @Prop({ type: Boolean, default: false }) public editable!: boolean;
  public headers: ColumnHeader[] = headers;

  public edit(item: Customer) {
    this.$router.push({ name: 'customer.edit', params: { id: item.userUid }, query: { ...this.$route.query } });
  }
}
