export default [
  {
    text: 'customer.table.userUid',
    value: 'userUid',
  },
  {
    text: 'customer.table.name',
    value: 'name',
  },
  {
    text: 'customer.table.email',
    value: 'email',
  },
  {
    text: 'customer.table.qr',
    value: 'qrCode',
    type: 'qr',
  },
];
