
import { Component, Vue, Watch } from 'vue-property-decorator';
import VWrapper from '@/components/shared/VWrapper.vue';
import { namespace } from 'vuex-class';
import { Venue } from '@/interfaces/models/Venue';
import TableTable from '@/components/table/TableTable.vue';
import VEmptyState from '@/components/shared/VEmptyState.vue';
import CustomerTable from '../../components/customer/CustomerTable.vue';

const customer = namespace('customer');
const venue = namespace('venue');

@Component({
  components: { CustomerTable, VEmptyState, TableTable, VWrapper },
})
export default class Customer extends Vue {
  @venue.State('active') public venue!: Venue;

  @customer.State('items') public customers!: Customer[];
  @customer.Action('fetch') public getCustomers!: any;

  public created() {
    if (!this.venue) {
      this.$startLoading('venue');
    }
  }

  public mounted() {
    if (!this.venue) {
      this.$startLoading('venue');
    }
  }

  @Watch('venue')
  public async onVenueChange() {
    if (this.venue && this.venue._id) {
      this.$startLoading('customer');
      await this.getCustomers(this.venue.customers);
      this.$stopLoading('customer');
    }
  }
}
